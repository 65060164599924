import React, {Component} from "react"
import styles from "./Footer.module.css"

interface Props {}

export class Footer extends Component<Props, {}> {
    public render() {
        return (
            <footer className={styles.root}>
                <div className={styles.wrapper}>
                    <address className={styles.address}></address>
                    <p className={styles.text}>
                        <strong>MIXED Institute of Cosmetology & Barber</strong>
                    </p>
                    <p className={styles.text}>5950 Florin Rd. #103 | Sacramento, CA 95823 </p>
                    <p className={styles.text}>Email Us ─ info@mixedinstitute.institute</p>
                    <p className={styles.text}>Call Us ─ 916 421 5950</p>
                </div>
            </footer>
        )
    }
}
